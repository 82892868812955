export default function useTransliterate(text: string) {
    const alphabetMap: {[key: string]: string} = {
      'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
      'е': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y',
      'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
      'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
      'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
      'щ': 'sch', 'ы': 'y', 'э': 'e', 'ю': 'yu', 'я': 'ya',
      
      // Казахские буквы
      'ә': 'a', 'ғ': 'g', 'қ': 'q', 'ң': 'ng', 'ө': 'o',
      'ұ': 'u', 'ү': 'y', 'һ': 'h', 'і': 'i',
      
      // Заглавные буквы
      'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
      'Е': 'E', 'Ж': 'Zh', 'З': 'Z', 'И': 'I', 'Й': 'Y',
      'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O',
      'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
      'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch', 'Ш': 'Sh',
      'Щ': 'Sch', 'Ы': 'Y', 'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya',
      
      // Казахские заглавные буквы
      'Ә': 'A', 'Ғ': 'G', 'Қ': 'Q', 'Ң': 'Ng', 'Ө': 'O',
      'Ұ': 'U', 'Ү': 'Y', 'Һ': 'H', 'І': 'I'
    };
  
    return text.split('').map(char => {
      // Если символ есть в алфавитном отображении, заменяем его
      if (alphabetMap.hasOwnProperty(char)) {
        return alphabetMap[char];
      }
      // Иначе оставляем символ без изменений
      return char;
    }).join('');
  }